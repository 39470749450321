import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../images/dating-app-logo-dark.jpg";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';
import DataService from '../services/data.service';
import { useNavigate } from 'react-router-dom';

const Payments = () => {
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  let user_id = JSON.parse(localStorage.getItem("d_user"));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const saleId = queryParams.get("saleID");
  const navigate = useNavigate();

  const data = {};
  data.userId = user_id
  data.saleId = saleId
  DataService.verifyPayment(data).then(
    (response) => {}
  );

  return (
    <>
      <div className="not_found">
        <Link to="/">
          <img src={Logo} className="not_logo" alt="" />
        </Link>
        <h2>Thank you for payment. Your credits should be available shortly.</h2>

        <p>If your credits do not appear, please contact support for assistance.</p>

        <Link to="/">
          {" "}
          <button className="main_button" onClick={navigate("/")}>
            Go Home
          </button>
        </Link>
      </div>
    </>
  );
};

export default Payments;

